import React from "react";
import Layout from "../components/layouts/layout";
//Image Banner
import { BASE_SITE_URL } from "../shared/constants";

const PrivacyPolicy = () => {
  return (
    <Layout
      seo={{
        title: "Privacy Policy",
        ogtitle: "Privacy Policy",
        description: "Welcome to Privacy Policy Page",
        ogurl: `${BASE_SITE_URL}/privacy-policy`,
      }}
    >
      <div className="mt-70">.</div>
      <div className="mt-70 body-container">
        <div className="title-header">
          <span className="red-line3letter">PRI</span>VACY POLICY
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="privacy-content">
          Our privacy policy discloses how we gather, use and protect your data.
          Below you can find the legal text.
        </div>
      </div>
      <div className="mt-30 ">
        <hr
          style={{ border: "1px solid #FAFAFA", background: "transparent" }}
        />
      </div>
      <div className="mt-70 body-container">
        <div className="mini-view-title">Updated 30 September 2020</div>
      </div>

      <div className="mt-30 body-container">
        <div className="image-banner-title1">
          <span className="red-line3letter">1. L</span>OGFILES
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          We collect personal data through our internet access logs. When you
          access our website, your internet address is automatically collected
          and is placed in our internet access logs (i.e., log files on server).
          We also record the URLs of the websites and pages you visit, the times
          and dates of such visits, information about the computer hardware and
          software you use.
          <div className="mt-30">
            This information may include Internet protocol (IP) addresses,
            browser type and version, internet service provider (ISP),
            referring/exit pages, operating system, date/time stamp, and/or
            clickstream data, number of visits, websites from which you accessed
            our site (Referrer), and websites that are accessed by your system
            via our site.
          </div>
          <div className="mt-30">
            The processing of this data is necessary for the provision and the
            security of our website. The legal basis for this processing is Art.
            6(1)(f) GDPR. We do not link these data to certain individuals.
            After the processing for the mentioned purposes, the data will be
            deleted.
          </div>
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="image-banner-title1">
          <span className="red-line3letter">2. C</span>ONTACT FORM
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          On our website we use several contact forms which can be used to get
          in contact with us electronically. To handle your request it is
          necessary to provide us with a valid email address, and, in addition,
          we store your IP address and the time of your request to prevent any
          misuse of our contact forms. Alternatively, you may contact us via the
          email addresses.
          <div className="mt-30">
            If you use one of these channels, the personal data transmitted will
            be stored automatically by Digiryte as a data controller to handle
            our contact with you. The legal basis for this processing is Art.
            6(1)(f) GDPR. In some cases we may ask you for further information,
            such as name, occupation, address or telephone number. Regarding the
            processing of this personal data you will be asked to grant your
            explicit consent. The legal basis for this processing is Art.
            6(1)(a) GDPR.
          </div>
        </div>
      </div>
      <div className="mt-30 body-container">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">3. R</span>egistration on our
          website (eventbrite)
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          If you make use of our website to register for certain services or
          events, or to download e-books you will be asked to provide personal
          data. The information inserted in the respective registration form
          will be transmitted to Digiryte as data controller. The legal basis
          for the processing of data - which is necessary to provide you with
          the respective service - is Art. 6(1)(b) GDPR. Regarding the
          processing of further personal data you will be asked to grant your
          explicit consent, and the legal basis for that processing is Art.
          6(1)(a) GDPR.
        </div>
      </div>
      <div className="mt-30 body-container">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">4. J</span>ob application
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          Our website offers the opportunity to apply for employment, either via
          a job advertisement published on our side, or as a speculative
          application. If you make use of these possibilities by providing your
          personal data, including contact details, experiences, roles, etc. or
          provide (solicited or unsolicited) special categories of personal data
          (see Section 7), the information inserted in the application form or
          otherwise provided (e.g., by you including it in a resume) will be
          transmitted to ThoughtWorks as data controller. With regard to the
          processing of this personal data you will be asked to grant your
          explicit consent. The legal basis for this processing is Art. 6(1)(a)
          GDPR. We store this personal data only for internal use to handle your
          application. We will delete your personal data on completion of the
          application process if further retention is not required for the
          establishment, exercise or defence of legal claims occurring out of
          the application process, or unless this personal data are required for
          the formation of or performance under a contract.
          <div className="mt-30">
            At your registration on the job application portal, your IP-address
            and date and time of your registration will be collected. This
            allows us to prevent misuse of our services. The legal basis for
            this processing is Art. 6(1)(f) GDPR.
          </div>
        </div>
      </div>
      <div className="mt-30 body-container">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">5. T</span>estimonials
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          We display personal testimonials of satisfied customers on our site in
          addition to other endorsements. With your consent we may post your
          testimonial along with your name. The legal basis for this processing
          is Art. 6(1)(a) GDPR. If you wish to update or delete your
          testimonial, you can contact us at{" "}
          <a href="mailto:hello@digiryte.com" className="atagclass">
            hello@digiryte.com
          </a>
          .
        </div>
      </div>
      <div className="mt-30 body-container">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">6. A</span>bout cookies
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          A cookie is a file containing an identifier (a string of letters and
          numbers) that is sent by a web server to a web browser and is stored
          by the browser. The identifier is then sent back to the server each
          time the browser requests a page from the server.
          <div className="mt-30">
            Cookies may be either "persistent" cookies or "session" cookies: a
            persistent cookie will be stored by a web browser and will remain
            valid until its set expiry date, unless deleted by the user before
            the expiry date; a session cookie, on the other hand, will expire at
            the end of the user session, when the web browser is closed.
          </div>
          <div className="mt-30">
            Cookies do not typically contain any information that personally
            identifies a user, but personal information that we store about you
            may be linked to the information stored in and obtained from
            cookies.{" "}
          </div>
        </div>
        <div className="mt-30">
          <div className="font-24-bold">Cookies that we use We use</div>
          <div className="article-main">
            {" "}
            cookies for the following purposes: Advertising - we use cookies to
            help us to display advertisements that will be relevant to you
            (cookies used for this purpose are: Google, Facebook, Lead
            Forensics, Lucky Orange); and
          </div>
          <div className="article-main mt-30">
            Analysis - we use cookies to help us to analyse the use and
            performance of our website and services.
          </div>
        </div>
        <div className="mt-30 font-24-bold">
          Cookies used by our service providers
        </div>
        <div className="article-main">
          {" "}
          Our service providers use cookies and those cookies may be stored on
          your computer when you visit our website.
        </div>
        <div className="article-main mt-30">
          {" "}
          We use Google Analytics to analyse the use of our website. Google
          Analytics gathers information about website use by means of cookies.
          The information gathered relating to our website is used to create
          reports about the use of our website. Google’s privacy policy is
          available at:
          <a
            rel="noreferrer"
            className="atagclass"
            target="_blank"
            href="https://www.google.com/policies/privacy/"
          >
            https://www.google.com/policies/privacy/
          </a>{" "}
        </div>
        <div className="mt-30 font-24-bold">Managing cookies</div>
        <div className="article-main">
          {" "}
          Most browsers allow you to refuse to accept cookies and to delete
          cookies. The methods for doing so vary from browser to browser, and
          from version to version. You can however obtain up-to-date information
          about blocking and deleting cookies via these links:
        </div>
        <div style={{ fontStyle: "italic" }} className="article-main mt-30">
          {" "}
          (a){" "}
          <a
            className="atagclass"
            rel="noreferrer"
            target="_blank"
            href="https://support.google.com/chrome/answer/95647?hl=en (Chrome);"
          >
            https://support.google.com/chrome/answer/95647?hl=en (Chrome);
          </a>{" "}
        </div>
        <div style={{ fontStyle: "italic" }} className="article-main mt-30">
          {" "}
          (b){" "}
          <a
            rel="noreferrer"
            className="atagclass"
            target="_blank"
            href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox);"
          >
            https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            (Firefox);
          </a>{" "}
        </div>
        <div style={{ fontStyle: "italic" }} className="article-main mt-30">
          {" "}
          (c){" "}
          <a
            rel="noreferrer"
            className="atagclass"
            target="_blank"
            href="http://www.opera.com/help/tutorials/security/cookies/ (Opera);"
          >
            http://www.opera.com/help/tutorials/security/cookies/ (Opera);
          </a>
        </div>
        <div style={{ fontStyle: "italic" }} className="article-main mt-30">
          {" "}
          (d){" "}
          <a
            rel="noreferrer"
            className="atagclass"
            target="_blank"
            href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete- manage-cookies (Internet Explorer);"
          >
            https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-
            manage-cookies (Internet Explorer);
          </a>
        </div>
        <div style={{ fontStyle: "italic" }} className="article-main mt-30">
          {" "}
          (e){" "}
          <a
            rel="noreferrer"
            className="atagclass"
            target="_blank"
            href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
          >
            https://support.apple.com/en-gb/guide/safari/sfri11471/mac (Safari);
          </a>
        </div>
        <div style={{ fontStyle: "italic" }} className="article-main mt-30">
          {" "}
          (f){" "}
          <a
            rel="noreferrer"
            className="atagclass"
            target="_blank"
            href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
          >
            https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
            (Edge).
          </a>
        </div>
        <div className="article-main mt-30">
          {" "}
          Blocking all cookies will have a negative impact upon the usability of
          many websites.
        </div>
      </div>
      <div className="mt-30 body-container">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">7. T</span>hird Party Application
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          Our website includes hyperlinks to, and details of, third party
          websites.
          <div className="mt-30">
            We have no control over, and are not responsible for, the privacy
            policies and practices of third parties.
          </div>
        </div>
      </div>
      <div className="mt-30 body-container">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">8. U</span>se of Social Media
          Plugins
        </div>
        <div className="article-main mt-30">
          Our website incorporates buttons for social sharing from networks such
          as Facebook and LinkedIn. These buttons allow you to share content
          from our website with your social networks. In order to protect your
          privacy, these buttons are not directly embedded into our website,
          which means no personal data is sent to these social networks unless
          you click the buttons. This implementation ensures that your data is
          protected while using our website, aligning with our legitimate
          interest in promoting our services in accordance with Art. 6 (1) lit.
          f DSGVO.
        </div>
      </div>

      <div className="mt-30 body-container">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">9. F</span>acebook conversion pixels
        </div>
        <div className="article-main mt-30">
          We use the “Custom Audience pixel” of Facebook Inc., 1601 S.
          California Ave, Palo Alto, CA 94304, USA (“Facebook”) on our website.
          With its help, we can keep track of what users do after they see or
          click on a Facebook advertisement. This enables us to monitor the
          effectiveness of Facebook ads for purposes of statistics and market
          research. Data collected in this way is anonymous to us, which means
          we cannot see the personal data of individual users. However, this
          data is saved and processed by Facebook. Facebook can connect this
          data with your Facebook account and use it for its own advertising
          purposes, in accordance with Facebook’s Data Policy which can be found
          at{" "}
          <a
            className="atagclass"
            rel="noreferrer"
            target="_blank"
            href="https://www.facebook.com/about/privacy/."
          >
            https://www.facebook.com/about/privacy/.
          </a>{" "}
          You can allow Facebook and its partners to place ads on and outside of
          Facebook. A cookie can also be saved on your device for these
          purposes.
          <div className="mt-30">
            Please click here if you would like to withdraw your consent{" "}
            <a
              className="atagclass"
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/settings/?tab=ads#_="
            >
              https://www.facebook.com/settings/?tab=ads#_=_
            </a>
          </div>
        </div>
      </div>
      <div className="mt-30 body-container">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">10. L</span>inkedIn Analytics and
          LinkedIn Ads
        </div>
        <div className="article-main mt-30">
          We use LinkedIn's conversion tracking and retargeting technologies on
          our website. This allows us to provide personalized advertisements to
          website visitors on LinkedIn and to generate anonymous reports
          regarding ad performance and user interaction with our site. The
          LinkedIn Insight Tag is embedded in our website and communicates with
          LinkedIn’s servers when you visit our website while logged into your
          LinkedIn account. For more details on data collection and how it is
          used by LinkedIn, as well as the rights you have to manage your
          privacy, please visit the{" "}
          <a
            className="atagclass"
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/legal/privacy-policy"
          >
            LinkedIn Privacy Policy.
          </a>{" "}
          If you wish to opt out of LinkedIn's tracking while logged into your
          account, you can do so via the{" "}
          <a
            className="atagclass"
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/psettings/enhanced-advertising"
          >
            Enhanced Advertising Settings.
          </a>{" "}
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">11. </span> LinkedIn Insight Tags
        </div>
        <div className=" mt-30 article-main">
          We use the “LinkedIn Insight Tag” of LinkedIn Corporation, 1000 W
          Maude Ave, Sunnyvale, CA 94085, USA (“LinkedIn”) on our website. It
          helps us with in-depth campaign reporting and insights about our
          website visitors. It can also be used to track conversions, retarget
          website visitors, and unlock additional insights about members
          interacting with our ads on LinkedIn.
        </div>
        <div className=" mt-30 article-main">
          {" "}
          The LinkedIn Insight Tag enables the collection of data regarding
          members’ visits to our website, including the URL, referrer, IP
          address, device and browser characteristics (User Agent), and
          timestamp. This data is encrypted, the IP addresses are truncated, and
          members’ direct identifiers are removed within seven days in order to
          make the data pseudonymous. This remaining, pseudonymized data is then
          deleted within 90 days.{" "}
        </div>
        <div className=" mt-30 article-main">
          LinkedIn does not share personal data with us, it only provides
          aggregated reports about the website audience and ad performance.
          LinkedIn also provides retargeting for website visitors, enabling us
          to show personalized ads off our website by using this data, but
          without identifying the member. LinkedIn members can control the use
          of their personal data for advertising purposes through their account
          settings.
        </div>
        <div className=" mt-30 article-main">
          All this in accordance with LinkedIn terms and cookie policies.
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="">
          <div
            style={{ textTransform: "uppercase" }}
            className="image-banner-title1"
          >
            <span className="red-line3letter">12. G</span>oogle Analytics
          </div>
        </div>
        <div className="mt-30 ">
          <div className="article-main">
            Our website uses Google Analytics, a web analysis service of Google,
            Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA,
            www.google.com (“Google Analytics” or “Google”). Google Analytics
            employs cookies that are stored to your computer in order to
            facilitate an analysis of your use of the site. The information
            generated by these cookies, such as time, place and frequency of
            your visits to our site, including your IP address, is transmitted
            to Google’s location in the US and stored there.
          </div>
          <div className="article-main">
            In using Google Analytics our website employs the extension
            “anonymizeIp”. In doing so, Google abbreviates and thereby
            anonymizes your IP address before transferring it from EU/EEA member
            states. Google uses this information to analyze your use of our
            site, to compile reports for us on internet activity and to provide
            other services relating to our website.
          </div>
          <div className="article-main">
            Google may also transfer this information to third parties where
            required to do so by law or where such third parties process this
            data on Google’s behalf. Google states that it will never associate
            your IP address with other data held by Google. You can prevent
            cookies from being installed by adjusting the settings on your
            browser software accordingly as noted elsewhere in this Privacy
            Policy. You should be aware, however, that by doing so you may not
            be able to make full use of all the functions of our website.
          </div>
          <div className="article-main">
            Google Analytics also offers a deactivation add-on for most current
            browsers that provides you with more control over what data Google
            can collect on websites you access. The add-on tells the JavaScript
            (ga.js) used by Google Analytics not to transmit any information
            about website visits to Google Analytics. However, the browser
            deactivation add-on offered by Google Analytics does not prevent
            information from being transmitted to us or to other web analysis
            services we may engage. Google Analytics also uses electronic images
            known as web beacons (sometimes called single pixel gifs) and are
            used along with cookies to compile aggregated statistics to analyze
            how our site is used. You can find additional information on how to
            install the browser add-on referenced above at the following link:
            <a
              className="atagclass"
              rel="noreferrer"
              target="_blank"
              href="https://tools.google.com/dlpage/gaoptout?hl=en."
            >
              https://tools.google.com/dlpage/gaoptout?hl=en.
            </a>{" "}
            <br />
            For the cases in which personal data is transferred to the US,
            Google has self-certified pursuant to the EU-US Privacy Shield{" "}
            <a
              className="atagclass"
              rel="noreferrer"
              target="_blank"
              href="https://www.privacyshield.gov/EU-US-Framework"
            >
              (https://www.privacyshield.gov/EU-US-Framework).
            </a>{" "}
          </div>
        </div>
      </div>
      <div className="mt-30 body-container ">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">13. </span> Google Tag Manager
          (GTM):
        </div>

        <div className=" mt-30 article-main">
          Google Tag Manager (GTM”) is a tag management system to manage
          JavaScript and HTML tags used for tracking and analytics on websites.
          Tags are small code elements that, among other things, are used to
          measure traffic and visitor behaviour: to understand the effect of
          online advertising and social channels; to set up remarketing and
          orientation towards target groups; and to test and optimize websites.
          GTM makes it easier for us to integrate and manage our tags. We use
          GTM on our website to include the following tracking tools (as in
          described in detail this Section 12):
        </div>
        <ul className=" mt-30 article-main">
          <li>Google Analytics</li>
          <li>Zoho CRM</li>
          <li>Facebook Conversion Pixels</li>
          <li>Twitter tailored audience</li>
        </ul>
        <div className=" mt-30 article-main">
          {" "}
          If you have performed deactivation, GTM takes this deactivation into
          account. For more information about GTM’s privacy practices can be
          found at{" "}
          <a
            rel="noreferrer"
            className="atagclass"
            target="_blank"
            href="https://policies.google.com/privacy?hl=en"
          >
            https://policies.google.com/privacy?hl=en
          </a>{" "}
          and terms of use at{" "}
          <a
            className="atagclass"
            rel="noreferrer"
            target="_blank"
            href="https://www.google.com/analytics/tag-manager/use-policy/"
          >
            https://www.google.com/analytics/tag-manager/use-policy/.
          </a>
        </div>
      </div>

      <div className="mt-30 body-container ">
        <div
          style={{ textTransform: "uppercase" }}
          className="image-banner-title1"
        >
          <span className="red-line3letter">14. </span> Zoom Communications
        </div>
        <div className=" mt-30 article-main">
          Zoom Video Communications is a remote conferencing services company
          headquartered in San Jose, California. It provides a remote
          conferencing service that combines video conferencing, online
          meetings, chat, and mobile collaboration. Zoom enables us to run
          virtual events and webinars for our audiences. The information
          collected during the virtual event / webinar registration process on
          our website is passed over to Zoom to ensure seamless user experience
          for the attendees. Details about Zoom’s policy for collecting and
          processing the user information can be found at{" "}
          <a
            className="atagclass"
            target="_blank"
            rel="noreferrer"
            href="https://zoom.us/privacy-and-legal"
          >
            https://zoom.us/privacy-and-legal
          </a>
        </div>
        <div className="mt-30 font-24-bold">Your rights</div>
        <div className=" mt-30 article-main">
          1.1 In this Section, we have summarised the rights that you have under
          data protection law. Some of the rights are complex, and not all of
          the details have been included in our summaries. Accordingly, you
          should read the relevant laws and guidance from the regulatory
          authorities for a full explanation of these rights.{" "}
        </div>
        <div className=" mt-30 article-main">
          1.2 Your principal rights under data protection law are: (a) the right
          to access; (b) the right to rectification; (c) the right to erasure;
          (d) the right to restrict processing; (e) the right to object to
          processing; (f) the right to data portability; (g) the right to
          complain to a supervisory authority; and (h) the right to withdraw
          consent.{" "}
        </div>
        <div className=" mt-30 article-main">
          1.3 You have the right to confirmation as to whether or not we process
          your personal data and, where we do, access to the personal data,
          together with certain additional information. That additional
          information includes details of the purposes of the processing, the
          categories of personal data concerned and the recipients of the
          personal data. Providing the rights and freedoms of others are not
          affected, we will supply to you a copy of your personal data. The
          first copy will be provided free of charge, but additional copies may
          be subject to a reasonable fee and will be supplied within 30 days.
        </div>
        <div className=" mt-30 article-main">
          1.4 You have the right to have any inaccurate personal data about you
          rectified and, taking into account the purposes of the processing, to
          have any incomplete personal data about you completed and all requests
          will be rectified within 30 days.
        </div>
        <div className=" mt-30 article-main">
          1.5 In some circumstances you have the right to the erasure of your
          personal data without undue delay and this will be completed within 30
          days. Those circumstances include: the personal data are no longer
          necessary in relation to the purposes for which they were collected or
          otherwise processed; you withdraw consent to consent-based processing;
          you object to the processing under certain rules of applicable data
          protection law; the processing is for direct marketing purposes; and
          the personal data have been unlawfully processed. However, there are
          exclusions of the right to erasure. The general exclusions include
          where processing is necessary: for exercising the right of freedom of
          expression and information; for compliance with a legal obligation; or
          for the establishment, exercise or defence of legal claims.
        </div>
        <div className=" mt-30 article-main">
          1.6 In some circumstances you have the right to restrict the
          processing of your personal data. Those circumstances are: you contest
          the accuracy of the personal data; processing is unlawful, but you
          oppose erasure; we no longer need the personal data for the purposes
          of our processing, but you require personal data for the
          establishment, exercise or defence of legal claims; and you have
          objected to processing, pending the verification of that objection.
          Where processing has been restricted on this basis, we may continue to
          store your personal data. However, we will only otherwise process it:
          with your consent; for the establishment, exercise or defence of legal
          claims; for the protection of the rights of another natural or legal
          person; or for reasons of important public interest.
        </div>
        <div className=" mt-30 article-main">
          1.7 You have the right to object to our processing of your personal
          data on grounds relating to your particular situation, but only to the
          extent that the legal basis for the processing is that the processing
          is necessary for: the performance of a task carried out in the public
          interest or in the exercise of any official authority vested in us; or
          the purposes of the legitimate interests pursued by us or by a third
          party. If you make such an objection, we will cease to process the
          personal information unless we can demonstrate compelling legitimate
          grounds for the processing which override your interests, rights and
          freedoms, or the processing is for the establishment, exercise or
          defence of legal claims.
        </div>
        <div className=" mt-30 article-main">
          1.8 You have the right to object to our processing of your personal
          data for direct marketing purposes (including profiling for direct
          marketing purposes). If you make such an objection, we will cease to
          process your personal data for this purpose.
        </div>
        <div className=" mt-30 article-main">
          1.9 To the extent that the legal basis for our processing of your
          personal data is:
        </div>
        <div className=" mt-30 article-main">(a) consent; or</div>
        <div className=" mt-30 article-main">
          (b) that the processing is necessary for the performance of a contract
          to which you are party or in order to take steps at your request prior
          to entering into a contract,
        </div>
        <div className=" mt-30 article-main">
          and such processing is carried out by automated means, you have the
          right to receive your personal data from us in a structured, commonly
          used and machine-readable format. However, this right does not apply
          where it would adversely affect the rights and freedoms of others.
        </div>
        <div className=" mt-30 article-main">
          1.10 If you consider that our processing of your personal information
          infringes data protection laws, you have a legal right to lodge a
          complaint with a supervisory authority responsible for data
          protection. You may do so in the EU member state of your habitual
          residence, your place of work or the place of the alleged
          infringement.
        </div>
        <div className=" mt-30 article-main">
          1.11 To the extent that the legal basis for our processing of your
          personal information is consent, you have the right to withdraw that
          consent at any time. Withdrawal will not affect the lawfulness of
          processing before the withdrawal.
        </div>
        <div className=" mt-30 article-main">
          1.12 You may exercise any of your rights in relation to your personal
          data by written notice to us.
        </div>
      </div>
      <div className="mt-30 ">
        <hr
          style={{ border: "1px solid #FAFAFA", background: "transparent" }}
        />
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
